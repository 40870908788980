import React from "react";
import { Container } from "../../Shared/Container/Container";
import EclipseSvg from "./assets/eclipse.svg";
import { AttendanceMockups } from "./components/AttendanceMockups";

export interface AttendanceTileProps {
  className?: string;
}

export const AttendanceTile = (props: AttendanceTileProps) => {
  const { className = "" } = props;
  return (
    <section className={`relative overflow-hidden py-40`}>
      <img src={EclipseSvg} alt="" className="eclipse absolute top-0 left-0t" />
      <Container>
        <div className="grid grid-cols-1 lg:grid-cols-2 z-10 relative">
          <div className="text-col  w-5/6">
            <h3 className="text-white text-5xl font-bold">
              Majte dochádzku pod kontrolou
            </h3>
            <p className="text-gray-200 mt-6 text-lg w-4/6">
              Softvér na riadenie podniku, ktorý pomáha firmám rásť a zlepšovať
              vzťahy so zákazníkmi.
            </p>
          </div>
          <div className="image">
            <AttendanceMockups />
          </div>
        </div>
      </Container>
    </section>
  );
};
