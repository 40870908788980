import React from "react";
import { Button } from "../../Shared/Button/Button";
import { HeroMockups } from "./components/HeroMockups";
import { HeroSvg } from "./components/HeroSvg";
import { heroHeading } from "./hero.module.scss";

export interface HeroProps {
  className?: string;
}

export const Hero = (props: HeroProps) => {
  const { className = "" } = props;
  return (
    <section className={`grid grid-cols-1 lg:grid-cols-2 gap-10`}>
      <article className="mt-20">
        <h1 className={`font-semibold text-6xl text-primary ${heroHeading}`}>
          Pozdvinite vaše podnikanie na vyššiu úroveň
        </h1>
        <p className="text-gray-800 lg:text-gray-500 mt-4 text-2xl">
          Softvér na riadenie podniku, ktorý pomáha firmám rásť a zlepšovať
          vzťahy so zákazníkmi.
        </p>
        <div className="button mt-10">
          <Button>Vyskúšať</Button>
        </div>
      </article>
      <article className="lg:mt-0 md:mt-40">
        <HeroMockups />
      </article>
    </section>
  );
};
