import GatsbyImage from "gatsby-image";
import React from "react";
import {
  heroDashboardImage,
  heroPhoneImage,
  phoneAdminImage
} from "../attendance-tile.module.scss";
import { useAttendanceTileImage } from "../hooks/useAttendanceTileImage";

export interface AttendanceMockupsProps {
  className?: string;
}

export const AttendanceMockups = (props: AttendanceMockupsProps) => {
  const { className = "" } = props;
  const { dashboard, phone, phoneAdmin } = useAttendanceTileImage();

  return (
    <div style={{
      height: 400,
      top: -40
    }} className={`relative`}>
      <div className={heroDashboardImage}>
        <GatsbyImage fluid={dashboard.childImageSharp.fluid} />
      </div>
      <div className={heroPhoneImage}>
        <GatsbyImage fluid={phone.childImageSharp.fluid} />
      </div>
      <div className={phoneAdminImage}>
        <GatsbyImage fluid={phoneAdmin.childImageSharp.fluid} />
      </div>
    </div>
  );
};
