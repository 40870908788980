import { Link } from "gatsby";
import React from "react";
import { routes } from "../../../lib/routes";
import { Container } from "../Container/Container";
import { LogoLink } from "./components/LogoLink";
import { MobileMenu } from "./components/MobileMenu";

const renderNavItems = () =>
  Object.values(routes).map((route) => (
    <Link
      key={route.to}
      activeClassName={"text-secondary"}
      className={
        "hover:text-secondary transition duration-200 font-semibold text-white text-xl hover:text-primary"
      }
      to={route.to}
    >
      {route.label}
    </Link>
  ));

export const Header = (props) => {
  return (
    <header className={"flex flex-col"}>
      <nav className="py-8">
        <Container className="flex justify-between">
          <div className="">
            <LogoLink />
          </div>
          <div className="hidden nav-items xl:flex justify-end space-x-10 text-primary items-center font-medium ">
            {renderNavItems()}
          </div>
          <div className="mm xl:hidden flex justify-end items-center">
            <MobileMenu />
          </div>
        </Container>
      </nav>
    </header>
  );
};
