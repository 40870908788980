import GatsbyImage from "gatsby-image";
import React from "react";
import { useHeroImages } from "../hooks/useHeroImages";
import { heroPhoneImage, heroDashboardImage, hero } from "../hero.module.scss";

export interface HeroMockupsProps {
  className?: string;
}

export const HeroMockups = (props: HeroMockupsProps) => {
  const { className = "" } = props;
  const { dashboard, phone } = useHeroImages();
  return (
    <div className={hero}>
      <div className={heroDashboardImage}>
        <GatsbyImage fluid={dashboard.childImageSharp.fluid} />
      </div>
      <div className={heroPhoneImage}>
        <GatsbyImage fluid={phone.childImageSharp.fluid} />
      </div>
    </div>
  );
};
