import React from "react";
import { Container } from "../Container/Container";

export interface FooterProps {
  className?: string;
}

export const Footer = (props: FooterProps) => {
  return (
    <footer className={props.className + ` bg-gray-50 py-10`}>
      <Container>
        <h1>Footer</h1>
      </Container>
    </footer>
  );
};
