import { useStaticQuery, graphql } from "gatsby";

export const useHeroImages = () => {
  const { dashboard, phone } = useStaticQuery(graphql`
    query useHeroImages {
      dashboard: file(
        absolutePath: {}
        relativePath: { eq: "home/hero/dashboard.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      phone: file(
        absolutePath: {}
        relativePath: { eq: "home/hero/phone.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return {
    dashboard,
    phone,
  };
};
