import React, { ReactNode } from "react";
import { button } from "./button.module.scss";

export interface ButtonProps {
  className?: string;
  children: ReactNode;
}

export const Button = (props: ButtonProps) => {
  const { className = "", children } = props;
  return (
    <div
      className={` py-4 px-8 cursor-pointer uppercase font-bold text-white inline-flex rounded-md shadow-md hover:shadow-xl  text-lg transition-all duration-300 ease-in-out  ${button}`}
    >
      {children}
    </div>
  );
};
