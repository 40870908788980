import { useStaticQuery, graphql } from "gatsby";

export const useAttendanceTileImage = () => {
  const { dashboard, phone, phoneAdmin } = useStaticQuery(graphql`
    query useAttendanceTileImage {
      dashboard: file(
        absolutePath: {}
        relativePath: { eq: "home/hero/dashboard.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      phone: file(
        absolutePath: {}
        relativePath: { eq: "home/hero/phone.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      phoneAdmin: file(
        absolutePath: {}
        relativePath: { eq: "home/attendance/phone-admin.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return {
    dashboard,
    phone,
    phoneAdmin,
  };
};
