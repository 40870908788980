import React from "react";
import { AttendanceTile } from "../components/Home/AttendanceTile/AttendanceTile";
import { HeroSvg } from "../components/Home/Hero/components/HeroSvg";
import { Hero } from "../components/Home/Hero/Hero";
import { Container } from "../components/Shared/Container/Container";
import Layout from "../components/Shared/Layout/Layout";
import { SEO } from "../components/Shared/SEO/SEO";

const IndexPage = () => (
  <Layout>
    <SEO description="This is an index page" />
    <Container className="lg:py-20">
      <Hero />
    </Container>
    <div>
      <HeroSvg />
      <AttendanceTile />
    </div>
  </Layout>
);

export default IndexPage;
