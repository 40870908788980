const primaryColor = "rgba(2,48,139,0.97)";

export const theme = {
  name: "Default",
  color: {
    background: "#ffffff",
    primary: primaryColor,
    primaryDark: "#000022",
    gray: "rgba(0, 0, 0, 0.12)",
    light: "#a2a1b71f",
  },
  borderRadius: "16px",
  spacing: {
    sectionMarginTop: "14rem",
  },
  breakpoints: {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xlg: 1600,
  },
};
