import React from "react";
import HeroSvgAsset from "../assets/hero.svg";

export interface HeroSvgProps {
  className?: string;
}

export const HeroSvg = (props: HeroSvgProps) => {
  const { className = "" } = props;
  return (
    <div
      style={{
        zIndex: -1,
      }}
      className={`absolute top-0 right-0 transform scale-150`}
    >
      <img src={HeroSvgAsset} alt="" />
    </div>
  );
};
